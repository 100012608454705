import React, { ReactNode, useState, useEffect } from "react";
import {
  Menu,
  X,
  Briefcase,
  GraduationCap,
  Code,
  Award,
  ChevronDown,
} from "lucide-react";
import { motion } from "framer-motion";
import Me from "./assets/Me.webp";
import RideTogether from "./assets/ride_together.png";
import Metal from "./assets/Metal.webp";
import FiveG from "./assets/5G.webp";
import Wi from "./assets/wi.webp";

interface SectionProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar p-4 sticky top-0 z-50 transition-all duration-300 ${
        scrolled ? "bg-opacity-90" : "bg-opacity-100"
      }`}
    >
      <video autoPlay loop muted className="navbar-video">
        <source src="/Butterfly.mp4" type="video/mp4" />
      </video>
      <div className="container mx-auto flex justify-between items-center relative">
        <motion.a
          href="#"
          className="text-white text-2xl font-bold"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Davino Joseph
        </motion.a>
        <div className="hidden md:flex space-x-4">
          {["About", "Experience", "Skills", "Projects"].map((item) => (
            <motion.a
              key={item}
              href={`#${item.toLowerCase()}`}
              className="navbar-link text-white hover:text-gray-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {item}
            </motion.a>
          ))}
        </div>
        <motion.button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden text-white"
          whileTap={{ scale: 0.9 }}
        >
          {isOpen ? <X /> : <Menu />}
        </motion.button>
      </div>
      {isOpen && (
        <motion.div
          className="md:hidden"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {["About", "Experience", "Skills", "Projects"].map((item) => (
            <motion.a
              key={item}
              href={`#${item.toLowerCase()}`}
              className="block text-white p-2 hover:bg-gray-700"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {item}
            </motion.a>
          ))}
        </motion.div>
      )}
    </nav>
  );
};

const Section: React.FC<SectionProps> = ({ title, icon, children }) => (
  <motion.section
    className="py-16"
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-6 flex items-center font-lobster">
        {icon}
        <span className="ml-2">{title}</span>
      </h2>
      {children}
    </div>
  </motion.section>
);

const SkillTag: React.FC<{ skill: string }> = ({ skill }) => (
  <motion.span
    className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    {skill}
  </motion.span>
);

const App = () => {
  return (
    <div className="bg-gray-100 min-h-screen font-roboto">
      <Navbar />

      <header className="header-bg-animation text-white py-32">
        <motion.div
          className="container mx-auto text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h1
            className="text-5xl md:text-6xl font-bold mb-4 font-lobster header-text-animation"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            Davino Joseph
          </motion.h1>
          <motion.p
            className="text-2xl md:text-3xl header-text-animation"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Software Engineer | Music Enthusiast
          </motion.p>
          <motion.div
            className="mt-8"
            animate={{ y: [0, 10, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <ChevronDown className="mx-auto header-text-animation" size={32} />
          </motion.div>
        </motion.div>
      </header>

      <Section title="About Me" icon={<Briefcase className="w-6 h-6" />}>
        <div className="flex flex-col md:flex-row items-center">
          <motion.img
            src={Me}
            alt="Davino Joseph"
            className="w-64 h-64 rounded-full mb-6 md:mb-0 md:mr-8"
            whileHover={{ scale: 1.1 }}
          />
          <p className="text-lg text-center md:text-left">
            Proficient in designing applications for Web and Android/IOS
            platforms using Flutter, Firebase, JavaScript, and MongoDB.
            Experienced in networking concepts like LTE and 5G Architecture
            using Linux. Beyond tech, I'm a music enthusiast, pouring my
            creativity into composing and producing music.
          </p>
        </div>
      </Section>

      <Section title="Experience" icon={<GraduationCap className="w-6 h-6" />}>
        <div className="space-y-6">
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md"
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-xl font-semibold">
              5G Engineer Trainee - Lekha Wireless Pvt Limited
            </h3>
            <p className="text-gray-600">2023–2024</p>
            <ul className="list-disc list-inside mt-2">
              <li>
                Gained experience in 5G tech through deep learning and
                real-world projects.
              </li>
              <li>
                Helped build a crucial emergency alert system for 5G phones,
                coded SIB6 information for the UE.
              </li>
              <li>
                Gained in-depth knowledge of 5G networks, setting myself up for
                future 5G endeavors.
              </li>
            </ul>
          </motion.div>
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md"
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-xl font-semibold">
              This Week India Media - Media Reporter
            </h3>
            <p className="text-gray-600">2023</p>
            <ul className="list-disc list-inside mt-2">
              <li>
                Covered diverse conferences: From tech unveilings to sports
                events, gained insights across healthcare, tech, and consumer
                goods.
              </li>
              <li>
                Content creation skills: Crafted engaging reports and content
                based on press event information.
              </li>
            </ul>
          </motion.div>
        </div>
      </Section>

      <Section title="Skills" icon={<Code className="w-6 h-6" />}>
        <div className="flex flex-wrap gap-2">
          {[
            "Flutter",
            "Firebase",
            "JavaScript",
            "MongoDB",
            "5G Architecture",
            "Linux",
            "MATLAB",
            "Python",
            "Verilog HDL",
            "VLSI Design",
            "Advanced Excel",
          ].map((skill) => (
            <SkillTag key={skill} skill={skill} />
          ))}
        </div>
      </Section>

      <Section title="Projects" icon={<Code className="w-6 h-6" />}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              title: "Carpooling Application",
              description:
                "Developed using Flutter framework with Firebase Authentication and Google Maps API integration.",
              image: RideTogether,
            },
            {
              title: "Metal Detector",
              description:
                "Built from scratch using Arduino, exploring safety applications.",
              image: Metal,
            },
            {
              title: "5G Connection Optimizer",
              description:
                "Developed a feature for 5G phones to find the best and most reliable connection, especially during emergencies.",
              image: FiveG,
            },
            {
              title: "Wireless Charging Research",
              description:
                "Explored methods for wireless phone charging using radio waves, similar to Wi-Fi technology.",
              image: Wi,
            },
          ].map((project) => (
            <motion.div
              key={project.title}
              className="bg-white p-6 rounded-lg shadow-md"
              whileHover={{ scale: 1.05 }}
            >
              <motion.img
                src={project.image}
                alt={project.title}
                className="w-48 h-48 object-cover mb-4 rounded-full mx-auto" // Circular avatar with specific width and height
                whileHover={{
                  scale: 1.3,
                  rotate: 270,
                  transition: { duration: 0.8, ease: "easeInOut" },
                }} // Exciting hover animation
              />

              <h3 className="text-xl font-semibold">{project.title}</h3>
              <p>{project.description}</p>
            </motion.div>
          ))}
        </div>
      </Section>

      <Section title="Achievements" icon={<Award className="w-6 h-6" />}>
        <ul className="space-y-4">
          {[
            "Volunteered 15 years at India's largest painting festival, 'Colorothon'.",
            "Volunteered with 'Raksana,' educating 20,000+ students and earning a Guinness World Record.",
            "Created the soundtrack for a short film by ST Joseph College of Communication.",
          ].map((achievement, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Award className="w-6 h-6 mr-2 flex-shrink-0 mt-1" />
              <span>{achievement}</span>
            </motion.li>
          ))}
        </ul>
      </Section>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <p>© 2024 Davino Joseph. All rights reserved.</p>
          <div className="mt-4">
            <motion.a
              href="mailto:davinojoseph1@gmail.com"
              className="text-blue-400 hover:text-blue-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              davinojoseph1@gmail.com
            </motion.a>
            <span className="mx-2">|</span>
            <span>+91 7975097384</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
